export const seo = {
  title: {
    pl: 'Współpraca Przewoźnik | Współpraca Transport | CARGONITE',
    en: 'Carrier Cooperation | Transport Patnership |',
  },
  description: {
    pl: 'Dołącz do partnerów CARGONITE i zyskaj gwarancję stałej współpracy. Korzystne warunki współpracy dla przewoźników. Sprawdź!',
    en: 'Join CARGONITE partners and get a guarantee of permanent cooperation. Favorable terms of cooperation for carriers. Check it out!',
  },
}
