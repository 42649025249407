export const introSectionContent = {
  title: {
    pl: 'Przewoźnik',
    en: 'Carrier',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: 'Dołącz do partnerów CARGONITE i zyskaj gwarancję stałej współpracy. Korzystne warunki współpracy dla przewoźników. Sprawdź!',
    en: 'Join CARGONITE partners and get a guarantee of permanent cooperation. Favorable terms of cooperation for carriers. Check it out!',
  },
}

export const featuresSectionContent = {
  title: {
    pl: 'Dołącz do partnerów CARGONITE i zyskaj!',
    en: 'Join the CARGONITE partners and gain!',
  },
  back: {
    pl: 'PRZEWOŹNIK',
    en: 'CARRIER',
  },
  desc: {
    pl: 'Korzystne warunki i gwarancja całorocznej współpracy. Skontaktuj się z nami i otrzymaj ofertę współpracy.',
    en: 'Favorable conditions and a guarantee of year-round cooperation. Contact us and receive a cooperation offer.',
  },
  features: [
    {
      title: {
        pl: 'Praca 365',
        en: 'Work 365',
      },
      desc: {
        pl: 'Traktujemy naszych Partnerów jako bardzo ważną część naszego zespołu. W ramach współpracy oferujemy podpisanie całorocznej umowy współpracy.',
        en: 'We treat our partners as a very important part of our team. As part of the cooperation, we offer signing a year-long cooperation agreement.',
      },
    },
    {
      title: { pl: 'Trasy w kółkach', en: 'Route in circles' },
      desc: {
        pl: 'W ramach całorocznej współpracy realizujemy trasy w kółkach, gwarantujemy min. 11000 km miesięcznie.',
        en: 'As part of the year-round cooperation, we carry out routes in circles, we guarantee min. 11,000 km per month.',
      },
    },
    {
      title: {
        pl: 'Preferowane kierunki',
        en: 'Preferred directions',
      },
      desc: {
        pl: 'Podejmując, współpracę z nami omawiamy preferowane kierunki i trasy.',
        en: 'Before starting cooperation we will discuss preferred directions and routes.',
      },
    },
    {
      title: {
        pl: 'Dedykowany Spedytor',
        en: 'Dedicated Forwarder',
      },
      desc: {
        pl: 'Współpracując z nami, otrzymujesz dedykowanego spedytora, który dba o bezpieczeństwo transportu i szybki załadunek. Jesteśmy pod telefonem i angażujemy się w cały proces dostawy. ',
        en: 'Cooperate with us and get a dedicated forwarder who takes care of transport safety and fast loading. We are on the phone and we are involved in the entire delivery process',
      },
    },
    {
      title: {
        pl: 'Krótkie terminy płatności',
        en: 'Fast payments',
      },
      desc: {
        pl: 'Krótkie terminy płatności i współpraca na preferowanych warunkach przez przewoźnika. ',
        en: "Short payment periods and cooperation on the carrier's preferred terms.",
      },
    },
    {
      title: { pl: 'Partnerskie relacje', en: 'Partnership relations' },
      desc: {
        pl: 'Stawiamy na partnerskie relacje i realne korzyści dla obu stron.',
        en: 'We focus on partnership relations and real benefits for both parties.',
      },
    },
  ],
}
