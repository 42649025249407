import React from 'react'
import Seo from '../../components/Seo'
import { seo } from './seo'
import { useLangContext } from '../../context/lang.context'
import IntroSection from './IntroSection/IntroSection'
import FormCarrier from './FormCarrier/FormCarrier'
import Features from './Features/Features'

const Carrier = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />
      <Features />
      <FormCarrier />
    </>
  )
}

export default Carrier
