import React from 'react'
import { featuresSectionContent as content } from '../content'
import FeaturesComponent from '../../../components/Features'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

const Features = () => {
  const query = useStaticQuery(graphql`
    query {
      image1: file(
        absolutePath: { regex: "/images/carrier/features/calendar.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/carrier/features/circle.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/carrier/features/directions.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/carrier/features/forwarder.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/carrier/features/time.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
      image6: file(
        absolutePath: { regex: "/images/carrier/features/handshake.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [JPG]
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <FeaturesComponent
      title={content.title}
      desc={content.desc}
      back={content.back}
      features={content.features.map((feature, id) => ({
        ...feature,
        image: getImage(query[`image${id + 1}`].childImageSharp),
      }))}
    />
  )
}

export default Features
