import React from 'react'
import Carrier from '../page/Carrier/Carrier'
import Layout from '../components/Layout'

const CarrierPage = () => (
  <Layout>
    <Carrier />
  </Layout>
)

export default CarrierPage
